import { MenuService } from './../menu/menu.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  CommunityUserService,
  SsuInformation,
} from '@neo-reward-engine-web/ecom-api';

/**
 * footer service that only retrieves account group info to build header links (e.g. social links or tos link)
 */
@Injectable({
  providedIn: 'root',
})
export class FooterService implements OnDestroy {
  private _ssuInfo$ = new BehaviorSubject<SsuInformation | null>(null);

  subscriptions: Subscription = new Subscription();

  /**
   *
   * @param _communityUserService injected to retrieve account group data
   * @param menuService injected to check if user is logged in
   */
  constructor(
    private _communityUserService: CommunityUserService,
    private readonly menuService: MenuService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get ssuInfo$(): Observable<SsuInformation | null> {
    return this._ssuInfo$.asObservable();
  }

  /** just retrieves the info, info is then available via ssuInfo$ observable to subscribers */
  public retrieveSsuInfo(): void {
    if (this.menuService.isLoggedIn) {
      this.subscriptions.add(this._communityUserService
        .ssuInformation()
        .subscribe((ssuInformation) => {
          this._ssuInfo$.next(ssuInformation);
        }));
    }
  }
}
